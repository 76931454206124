<template>
  <div class='wallet-detail'>
<!--    Wallet 4-->
    <a-tabs v-if="walletDetail && walletDetail.type === 'RECEIVE' && walletDetail.code === 'JACKPOT'" class="transaction-tabs" default-active-key="1">
      <a-tab-pane key="1" tab="Plus">
        <div class="transaction-content">
          <div class='actions d-flex justify-end align-center mb-20'>
            <a-input class='mr-15 w-200' placeholder='Wallet address...' v-model='queryPlus.wallet' @change='onChangeFilterPlus' />
            <a-button @click="onRefreshDataPlus" class="mr-15">
              <a-icon type='sync' />
              Refresh
            </a-button>
          </div>
          <a-table :columns='columns4Plus' :data-source='listLogs4Plus' row-key='_id' :loading='loading' :scroll='{ x: 800}'
                   :pagination="{
                current: queryPlus.page,
                pageSize: queryPlus.size,
                total: totalPlus
             }" @change="onChangeTablePlus">
            <template slot='stt' slot-scope='item, data, index'> {{ (query.page - 1) * query.size + index + 1 }}</template>
            <template slot='txhash' slot-scope='item'>
              <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.txhash)'/>
              <span>{{ item.txhash }}</span>
            </template>
            <template slot='created' slot-scope='item'>
              <span>{{ item.created ? convertGmt9FromUtc(item.created, 'DD-MM-YYYY HH:mm:ss') : '' }}</span>
            </template>
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Minus" :force-render="true">
        <div class="transaction-content">
          <div class='actions d-flex justify-end align-center mb-20'>
            <a-input class='mr-15 w-200' placeholder='Wallet address...' v-model='queryMinus.wallet' @change='onChangeFilterMinus' />
            <a-button @click="onRefreshDataMinus" class="mr-15">
              <a-icon type='sync' />
              Refresh
            </a-button>
          </div>
          <a-table :columns='columns4Minus' :data-source='listLogs4Minus' row-key='txhash' :loading='loading' :scroll='{ x: 1300}'
                   :pagination="{
                current: queryMinus.page,
                pageSize: queryMinus.size,
                total: totalMinus
             }" @change="onChangeTableMinus">
            <template slot='stt' slot-scope='item, data, index'> {{ (query.page - 1) * query.size + index + 1 }}</template>
            <template slot='txhash' slot-scope='item'>
              <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.txhash)'/>
              <span>{{ item.txhash }}</span>
            </template>
            <template slot='wallet' slot-scope='item'>
              <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.wallet)'/>
              <span>{{ item.wallet }}</span>
            </template>
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Edit fee" :force-render="true">
        <div class="transaction-content">
          <a-row>
            <a-col :xs="24" :md="12">
              <a-form-item>
                <a-input-number v-model="feePercent" style="width: 100%" size="large" :min="0" placeholder="Enter % fee" />
              </a-form-item>
              <div style="text-align: right;">
                <a-button :loading="loadingSubmitChangeFee" :disabled="!feePercent || feePercent < 0" type="primary" @click="onChangeFee">Submit</a-button>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div v-else>
<!--      Wallet 1, 2-->
      <div v-if="walletDetail && walletDetail.type === 'SPEND'" style="padding: 24px;">
        <div class='actions d-flex justify-end align-center mb-20'>
          <!--        <a-select style="width: 400px;" class="mr-10" placeholder="Wallet address" :options="optionsWalletHistories" @change='onChangeFilter' v-model="query.wallet" />-->
          <a-button @click="onResetData" class="mr-15">
            <a-icon type='sync'/>
            Refresh
          </a-button>
        </div>
        <a-table :columns='renderColumns' :data-source='renderList' row-key='_id' :loading='loading' :scroll='{ x: 800}'
                 :pagination="{
                  current: query.page,
                  pageSize: query.size,
                  total: total
               }" @change="onChangeTable">
          <template slot='stt' slot-scope='item, data, index'> {{ (query.page - 1) * query.size + index + 1 }}</template>
          <template slot='txhash' slot-scope='item'>
            <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.txhash)'/>
            <a-tooltip>
              <template slot="title">
                {{item.txhash}}
              </template>
              <span>{{ item.txhash }}</span>
            </a-tooltip>
          </template>
          <template slot='created' slot-scope='item'>
            <span>{{ item.created ? convertGmt9FromUtc(item.created, 'DD-MM-YYYY HH:mm:ss') : '' }}</span>
          </template>
        </a-table>
      </div>

<!--      Wallet 3-->
      <div v-else>
        <a-tabs class="transaction-tabs" default-active-key="1">
          <a-tab-pane key="1" tab="Transaction histories">
            <div class="transaction-content">
              <div class='actions d-flex justify-end align-center mb-20'>
                <a-input class='mr-15 w-200' placeholder='Wallet address...' v-model='query3.wallet' @change='onChangeFilter' />
                <a-button @click="onRefreshData3" class="mr-15">
                  <a-icon type='sync' />
                  Refresh
                </a-button>
              </div>
              <a-table :columns='renderColumns' :data-source='renderList' row-key='_id' :loading='loading' :scroll='{ x: 800}'
                       :pagination="{
                  current: query3.page,
                  pageSize: query3.size,
                  total: total
               }" @change="onChangeTable">
                <template slot='stt' slot-scope='item, data, index'> {{ (query3.page - 1) * query3.size + index + 1 }}</template>
                <template slot='txhash' slot-scope='item'>
                  <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.txhash)'/>
                  <span>{{ item.txhash }}</span>
                </template>
                <template slot='created' slot-scope='item'>
                  <span>{{ item.created ? convertGmt9FromUtc(item.created, 'DD-MM-YYYY HH:mm:ss') : '' }}</span>
                </template>
              </a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="Edit fee" :force-render="true">
            <div class="transaction-content">
              <a-row>
                <a-col :xs="24" :md="12">
                  <a-form-item>
                    <a-input-number v-model="feePercent" style="width: 100%" size="large" :min="0" placeholder="Enter % fee" />
                  </a-form-item>
                  <div style="text-align: right;">
                    <a-button :loading="loadingSubmitChangeFee" :disabled="!feePercent || feePercent < 0" type="primary" @click="onChangeFee">Submit</a-button>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {copyURL} from "@/helpers/common";
import SystemWalletsService from "@/services/app/system-wallets.service";
import WalletService from "@/services/app/wallet.service";
import moment from "moment";
import TransactionsRechargeList from "@/views/Transactions/TransactionsRechargeList.vue";
import TransactionsWithDrawList from "@/views/Transactions/TransactionsWithDrawList.vue";
import {convertGmt9, convertGmt9FromUtc} from "@/helpers/time";

export default {
  name: 'CreatePoolList2',
  components: {TransactionsWithDrawList, TransactionsRechargeList},
  data() {
    return {
      breadcrumb: [],
      query: {
        page: 1,
        size: 10,
        wallet: ''
      },
      query3: {
        page: 1,
        size: 10,
        wallet: ''
      },
      queryPlus: {
        page: 1,
        size: 10,
        wallet: ''
      },
      queryMinus: {
        page: 1,
        size: 10,
        wallet: ''
      },
      timeout: null,
      listLogs: [],
      total: 0,
      totalMinus: 0,
      totalPlus: 0,
      loading: false,
      feePercent: null,
      loadingSubmitChangeFee: false,
      columns: [
        {
          title: 'Order',
          width: 80,
          key: 'stt',
          scopedSlots: {customRender: 'stt'}
        },
        {
          title: 'TX Hash',
          scopedSlots: {customRender: 'txhash'},
          ellipsis: true,
          key: 'txhash',
        },
        {
          title: 'Description',
          ellipsis: true,
          key: 'description',
          dataIndex: 'description',
        },
        {
          title: 'Created at',
          width: 200,
          key: 'created',
          scopedSlots: {customRender: 'created'},
        },
      ],
      columns3: [
        {
          title: 'Order',
          width: 80,
          key: 'stt',
          scopedSlots: {customRender: 'stt'}
        },
        {
          title: 'TX Hash',
          scopedSlots: {customRender: 'txhash'},
          ellipsis: true,
          key: 'txhash',
        },
        {
          title: 'Amount',
          ellipsis: true,
          key: 'amount',
          dataIndex: 'totalFeesSystem',
          width: 140,
        },
        {
          title: 'Created at',
          width: 200,
          key: 'created',
          scopedSlots: {customRender: 'created'},
        },
      ],
      columns4Plus: [
        {
          title: 'Order',
          width: 80,
          key: 'stt',
          scopedSlots: {customRender: 'stt'}
        },
        {
          title: 'TX Hash',
          scopedSlots: {customRender: 'txhash'},
          ellipsis: true,
          key: 'txhash',
        },
        {
          title: 'Amount',
          ellipsis: true,
          key: 'amount',
          dataIndex: 'totalFeesJackpot',
          width: 140,
        },
        {
          title: 'Created at',
          width: 200,
          key: 'created',
          scopedSlots: {customRender: 'created'},
        },
      ],
      columns4Minus: [
        {
          title: 'Order',
          width: 80,
          key: 'stt',
          scopedSlots: {customRender: 'stt'}
        },
        {
          title: 'TX Hash',
          scopedSlots: {customRender: 'txhash'},
          ellipsis: true,
          key: 'txhash',
        },
        {
          title: 'Wallet address',
          scopedSlots: {customRender: 'wallet'},
          ellipsis: true,
          key: 'wallet',
          width: 320
        },
        {
          title: 'Amount',
          ellipsis: true,
          key: 'amount',
          dataIndex: 'totalFeesJackpot',
          width: 160,
        },
        {
          title: 'Rank',
          width: 140,
          key: 'created',
          scopedSlots: {customRender: 'created'},
        },
      ],
      listWalletHistories: [],
      walletDetail: null,
      walletHistories: [],
      listLogs3: [],
      listLogs4Plus: [],
      listLogs4Minus: [],
    }
  },
  async mounted() {
    this.setBreadcrumb([
      {
        path: '/create-pool/2',
        label: 'Create pool'
      }
    ])
    await this.onFetchDetailWallet()
    if (this.walletDetail) {
      if (this.walletDetail.type === 'RECEIVE' && this.walletDetail.code === 'SYSTEM_FEE') {
        await this.onFetchListReceiveFee()
      } else if (this.walletDetail.type === 'RECEIVE' && this.walletDetail.code === 'JACKPOT') {
        await Promise.all([this.onFetchListReceiveFeePlus(), this.onFetchListReceiveFeeMinus()])
      } else {
        // await this.onFetchListWalletHistories()
        await this.onFetchData()
      }
    }

  },
  computed: {
    moment() {
      return moment
    },
    renderList() {
      let output = []
      if (this.walletDetail) {
        if (this.walletDetail.type === 'RECEIVE' && this.walletDetail.code === 'SYSTEM_FEE') {
          output = this.listLogs3
        }
        if (this.walletDetail.type === 'SPEND') {
          output = this.listLogs
        }
      }
      return output
    },
    renderColumns() {
      let output = []
      if (this.walletDetail) {
        if (this.walletDetail.type === 'RECEIVE' && this.walletDetail.code === 'SYSTEM_FEE') {
          output = this.columns3
        }
        if (this.walletDetail.type === 'SPEND') {
          output = this.columns
        }
      }
      return output
    }
  },
  // watch: {
  //   breadcrumb: function (val) {
  //     this.setBreadcrumb(val)
  //   }
  // },
  methods: {
    convertGmt9FromUtc,
    convertGmt9,
    copyURL,
    async onResetData() {
      this.query.page = 1
      await this.onFetchListByType()
    },
    async onFetchDetailWallet() {
      const walletId = this.$route.params.id
      if (!walletId) return
      const response = await WalletService.getDetail(walletId)
      if (response.code) {
        this.walletDetail = response.data
        this.breadcrumb = [
          {
            path: `/wallets/${this.$route.params.id}`,
            label: response.data.name
          }
        ]
        this.feePercent = response.data.feePercent
        this.query.wallet = response.data.address
      }
    },
    async onChangeFee() {
      const walletId = this.$route.params.id
      if (!walletId) return
      this.loadingSubmitChangeFee = true
      try {
        const response = await WalletService.changeFee(walletId, {
          fee: this.feePercent
        })
        if (response.code === 1) {
          this.$notification.success({
            message: 'Change fee successfully'
          })
        } else {
          this.$notification.error({
            message: response.message
          })
        }
      } catch (e) {
        this.$notification.error({
          message: e.message
        })
      } finally {
        this.loadingSubmitChangeFee = false
      }
    },
    async onRefreshDataPlus() {
      this.queryPlus.page = 1
      this.queryPlus.wallet = ''
      await this.onFetchListByType()
    },
    async onRefreshDataMinus() {
      this.queryMinus.page = 1
      this.queryMinus.wallet = ''
      await this.onFetchListByType()
    },
    async onRefreshData3() {
      this.query3.page = 1
      this.query3.wallet = ''
      await this.onFetchListByType()
    },
    async onFetchListWalletHistories() {
      const walletId = this.$route.params.id
      if (!walletId) return
      const response = await SystemWalletsService.getListWalletAddressHistory(walletId)
      if (response.code === 1) {
        this.walletHistories = this.getOldValueByKey(response.data, 'address') || []
      }
    },
    async onFetchListReceiveFee() {
      const response = await SystemWalletsService.getWalletTransactionLogs34(this.query3)
      if (response.code === 1) {
        this.listLogs3 = response.data || []
        this.total = response.totalElement
      }
    },
    async onFetchListReceiveFeePlus() {
      const response = await SystemWalletsService.getWalletTransactionLogs34(this.queryPlus)
      if (response.code === 1) {
        this.listLogs4Plus = response.data || []
        this.totalPlus = response.totalElement
      }
    },
    async onFetchListReceiveFeeMinus() {
      const response = await SystemWalletsService.getWalletTransactionLogs34Minus(this.queryMinus)
      if (response.code === 1) {
        this.listLogs4Minus = response.data || []
        this.totalMinus = response.totalElement
      }
    },
    getOldValueByKey(data, key) {
      if (!data.length) return
      return data.map(item => item.oldValue[key]) || []
    },
    async onFetchListByType() {
      if (this.walletDetail) {
        if (this.walletDetail.type === 'SPEND') {
          await this.onFetchData()
        }
        if (this.walletDetail.type === 'RECEIVE' && this.walletDetail.code === 'SYSTEM_FEE') {
          await this.onFetchListReceiveFee()
        }
        if (this.walletDetail.type === 'RECEIVE' && this.walletDetail.code === 'JACKPOT') {
          await Promise.all([this.onFetchListReceiveFeePlus(), this.onFetchListReceiveFeeMinus()])
        }
      }
    },
    async onFetchData() {
      this.loading = true
      const response = await SystemWalletsService.getWalletTransactionLogs12(this.query)
      if (response.code === 1) {
        this.listLogs = response.data || []
        this.total = response.totalElement || 0
      }
      this.loading = false
    },
    onChangeFilter() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.query.page = 1
        this.onFetchListByType();
      }, 800);
    },
    onChangeFilterPlus() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.queryPlus.page = 1
        this.onFetchListByType();
      }, 800);
    },
    onChangeFilterMinus() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.queryMinus.page = 1
        this.onFetchListByType();
      }, 800);
    },
    onChangeTable(val) {
      this.query.page = val.current
      this.onFetchListByType()
    },
    onChangeTablePlus(val) {
      this.queryPlus.page = val.current
      this.onFetchListByType()
    },
    onChangeTableMinus(val) {
      this.queryMinus.page = val.current
      this.onFetchListByType()
    },
  }
}
</script>

<style scoped>
.transaction-content {
  padding: 0 16px;
}
</style>
